import { useTheme } from "@emotion/react";

import { Skeleton } from "../Skeleton";

import type { TypographyProps } from "./Typography.types";

import * as styles from "./Typography.styles";

/**
 * Documentation:
 * https://aviary.docs.fullscript.cloud/design-tokens/Typography
 */
const Typography = ({
  type = "body",
  sizeOverride,
  isIndented,
  isSecondaryWeight = false,
  isFullWidth,
  isMarginless,
  isSubdued = false,
  isSkeleton,
  children,
  customStyles,
  ref: unusedRef,
  dataE2e,
  ...rest
}: TypographyProps) => {
  const theme = useTheme();

  const titleStyleBuilder = () => {
    return [
      styles.titleBase,
      styles.titleSizes[type](isSecondaryWeight, theme),
      isSubdued && styles.isSubdued,
      isIndented && styles.indented,
      isFullWidth && styles.fullwidth,
      !isMarginless && styles.margin,
      sizeOverride && styles.sizeOverride[sizeOverride](isSecondaryWeight, theme),
      customStyles && customStyles,
    ];
  };

  const paragraphStyleBuilder = () => {
    return [
      styles.paragraphBase,
      styles.paragraphSizes[type](isSecondaryWeight, theme),
      isSubdued && styles.isSubdued,
      isFullWidth && styles.fullwidth,
      !isMarginless && styles.margin,
      sizeOverride && styles.sizeOverride[sizeOverride](isSecondaryWeight, theme),
      customStyles && customStyles,
    ];
  };

  const renderType = () => {
    switch (type) {
      case "h1":
        return (
          <h1 css={titleStyleBuilder()} data-e2e={dataE2e} {...rest}>
            {children}
          </h1>
        );
      case "h2":
        return (
          <h2 css={titleStyleBuilder()} data-e2e={dataE2e} {...rest}>
            {children}
          </h2>
        );
      case "h3":
        return (
          <h3 css={titleStyleBuilder()} data-e2e={dataE2e} {...rest}>
            {children}
          </h3>
        );
      case "h4":
        return (
          <h4 css={titleStyleBuilder()} data-e2e={dataE2e} {...rest}>
            {children}
          </h4>
        );
      case "h5":
        return (
          <h5 css={titleStyleBuilder()} data-e2e={dataE2e} {...rest}>
            {children}
          </h5>
        );
      default:
        return (
          <p css={paragraphStyleBuilder()} data-e2e={dataE2e} {...rest}>
            {children}
          </p>
        );
    }
  };

  const getSize = () => {
    if (sizeOverride) {
      return sizeOverride;
    }

    return type ? type : "body";
  };

  const getElement = () => {
    if (isSkeleton) {
      return <Skeleton type="typography" typographySize={getSize()} isFullWidth={isFullWidth} />;
    }

    return renderType();
  };

  return getElement();
};

export { Typography };
