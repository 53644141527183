import { useExperiment } from "@shared/hooks/useExperiment/useExperiment";
import { useFlippers } from "@shared/hooks/useFlippers/useFlippers";

const usePhoenixSecondaryButtonExperiment = () => {
  const [isSecondaryButtonFlipper] = useFlippers("phoenix_secondary_button_experiment");

  const group = useExperiment({
    experiment: "phoenix_secondary_ab_test",
    groups: ["CONTROL", "EXPERIMENT"],
    shouldLog: isSecondaryButtonFlipper,
    authedExperiment: true,
    unauthedExperiment: false,
    e2eGroup: "CONTROL",
    owner: "phoenix",
  });

  return isSecondaryButtonFlipper && group === "EXPERIMENT";
};

export { usePhoenixSecondaryButtonExperiment };
