import { css, type Theme } from "@emotion/react";

import { animations, dimensions, helpers, layers, timing } from "@styles";

export const base = (theme: Theme) => css`
  ${animations.transition()};
  position: relative;
  border-radius: ${dimensions.borderRadius} ${dimensions.borderRadius};
  z-index: ${layers.indexModalMax};
  box-shadow: 0 2px 4px 0 ${helpers.hexToRgba(theme.system.borderBase, 0.2)};
  min-width: 10rem;
  max-width: 24.5rem;
  display: flex;
  align-content: stretch;
`;

export const contentWrapper = css`
  display: flex;
`;

export const content = (theme: Theme) => css`
  padding: 1.25rem 2.5rem 1.25rem 1.25rem;
  flex: 1;
  color: ${theme.text.onBackground};
  background-color: ${theme.surface.overlayDark};
  border-radius: 0 ${dimensions.borderRadius} ${dimensions.borderRadius} 0;
  word-break: break-word;

  a:not([role="button"]) {
    ${animations.transition()};
    color: ${theme.text.onBackground};
    text-decoration: underline;

    // TODO: Change this to text.onBackgroundHover
    &:hover {
      color: ${theme.system.borderBase};
    }
  }
  strong {
    color: ${theme.text.onBackground};
  }
`;

export const timeDown = (initialTime: number, remaining: number) => (theme: Theme) => css`
  background-color: ${theme.surface.overlayDark};
  flex: 1;
  height: ${(remaining / initialTime) * 100}%;
  transition: height ${timing.ultraFast} linear;
  width: 100%;
  margin-top: auto;
  margin-bottom: 0;
  border-radius: ${dimensions.borderRadius} 0 0 ${dimensions.borderRadius};
`;

export const timer = (theme: Theme) => css`
  width: 0.25rem;
  position: relative;
  display: flex;
  border-radius: ${dimensions.borderRadius} 0 0 ${dimensions.borderRadius};
  background-color: ${theme.success.backgroundMutedActive};
`;

export const timerTypes = {
  normal: (theme: Theme) => css`
    background-color: ${theme.system.borderBase};
  `,
  error: (theme: Theme) => css`
    background-color: ${theme.danger.backgroundMutedActive};
  `,
  success: (theme: Theme) => css`
    background-color: ${theme.success.backgroundMutedActive};
  `,
};

export const progressTypes = {
  normal: (theme: Theme) => css`
    background-color: ${helpers.hexToRgba(theme.surface.overlayDark, 0.5)};
  `,
  error: (theme: Theme) => css`
    background-color: ${helpers.hexToRgba(theme.danger.backgroundHover, 0.5)};
  `,
  success: (theme: Theme) => css`
    background-color: ${helpers.hexToRgba(theme.success.backgroundHover, 0.5)};
  `,
};

export const types = {
  normal: (theme: Theme) => css`
    background-color: ${theme.surface.overlayDark};
  `,
  error: (theme: Theme) => css`
    background-color: ${theme.danger.backgroundBase};
  `,
  success: (theme: Theme) => css`
    background-color: ${theme.success.backgroundBase};
  `,
};

export const icon = css`
  margin-top: 5px;
  margin-right: 0.5rem;
`;
