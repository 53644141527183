import { useTheme } from "@emotion/react";
import { faSpinnerThird } from "@fortawesome/pro-regular-svg-icons";
import { colorProfileMapper } from "aviary-tokens";
import type { HTMLProps, Ref } from "react";
import { useTranslation } from "react-i18next";

import { l } from "@aviary/locales/i18n";
import { type AviarySize } from "@aviary/types";
import { FontAwesomeIcon } from "@shared/react-fontawesome";
import { Link } from "@shared/react-router-dom";

import { Skeleton } from "../../Skeleton";
import type { ButtonOrLinkType } from "../Button.types";

import * as styles from "./DeprecatedButton.styles";

export type ButtonAviarySize = Extract<AviarySize, "xsmall" | "small" | "normal" | "large">;

const DeprecatedButton = ({
  children,
  disabled,
  href,
  innerRef,
  isCircular,
  isColor = "primary",
  isFullWidth,
  isIcon,
  isLight,
  isLoading,
  isOutlined,
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  size = "normal", // needs fixing as ButtonOrLinkType has size that is different than what is typed here
  isSkeleton = false,
  isText,
  latch,
  noStyle,
  replace,
  to,
  type,
  isLoaderDisabled,
  ...rest
}: ButtonOrLinkType) => {
  const { t } = useTranslation("aviary");
  const currentTheme = useTheme();
  const themeColors = colorProfileMapper(currentTheme);

  const buttonBuilder = () => {
    if (noStyle) return styles.button.noStyle;

    return [
      isLoading && styles.loadingStyles,
      styles.button.base,
      styles.normalStyles(themeColors[isColor], currentTheme),
      styles.latch(themeColors[isColor], currentTheme)[latch],
      disabled && styles.latch(themeColors[isColor], currentTheme).disabled,
      isFullWidth && styles.button.fullwidth,
      isCircular && styles.circular,
      isText && styles.textTypeStyle(themeColors[isColor]),
      isOutlined && styles.outlinesStyles(themeColors[isColor], currentTheme),
      styles.button[size],
      isIcon && iconBuilder(),
      isColor === "system" && systemButtonBuilder(),
      isLight && lightButtonBuilder(),
    ];
  };

  const lightButtonBuilder = () => {
    return (
      !noStyle && [
        styles.lightStyles.base(currentTheme),
        isOutlined && styles.lightStyles.outlined(currentTheme),
        isText && styles.lightStyles.isText(currentTheme),
        isIcon && styles.lightStyles.isIcon(currentTheme),
        isIcon && isText && styles.lightStyles.isText(currentTheme),
        isIcon && isOutlined && styles.lightStyles.outlined(currentTheme),
      ]
    );
  };

  const iconBuilder = () => {
    return (
      !noStyle && [
        isIcon && styles.baseIconStyles,
        isIcon && styles.iconStyles[size],
        styles.normalSVG(currentTheme),
        isOutlined && styles.outlineSVG(themeColors[isColor]),
        isText && styles.textTypeSVG(themeColors[isColor]),
      ]
    );
  };

  const systemButtonBuilder = () => {
    return !noStyle && [styles.systemProfileStyles(themeColors.system)];
  };

  const loaderBuilder = () => {
    return (
      !noStyle && [
        styles.loaderIcon,
        styles.normalSVG(currentTheme),
        isOutlined && styles.outlineSVG(themeColors[isColor]),
        isText && styles.textTypeSVG(themeColors[isColor]),
        isLight && styles.lightStyles.isIcon(currentTheme),
      ]
    );
  };

  const renderLoader = () => {
    if (!noStyle && !isLoaderDisabled && isLoading) {
      return (
        <div css={styles.loader}>
          <FontAwesomeIcon
            css={loaderBuilder()}
            icon={faSpinnerThird}
            spin
            title={t(l.aviary.button.Loading)}
          />
        </div>
      );
    }
  };

  const getValidButtonType = () => {
    if (type === "submit" || type === "reset") {
      return type;
    } else {
      return "button";
    }
  };

  const button = () => (
    <button
      css={buttonBuilder()}
      disabled={disabled}
      {...(rest as Omit<HTMLProps<HTMLButtonElement>, "size">)}
      ref={innerRef as Ref<HTMLButtonElement>}
      type={getValidButtonType()}
    >
      {renderLoader()}
      {children}
    </button>
  );

  const anchor = () => (
    <a
      css={buttonBuilder()}
      role="button"
      href={href}
      {...(rest as Omit<HTMLProps<HTMLAnchorElement>, "size">)}
      ref={innerRef as Ref<HTMLAnchorElement>}
    >
      {renderLoader()}
      {children}
    </a>
  );

  const routerLink = () => (
    <Link
      css={buttonBuilder()}
      to={to}
      replace={replace}
      data-testid="Link"
      {...rest}
      ref={innerRef as Ref<HTMLAnchorElement>}
    >
      {renderLoader()}
      {children}
    </Link>
  );

  const getElement = () => {
    if (isSkeleton) {
      return <Skeleton type="button" buttonSize={size} isFullWidth={isFullWidth} />;
    } else if (!disabled) {
      if (to) {
        return routerLink();
      } else if (href) {
        return anchor();
      }
    }

    return button();
  };

  return getElement();
};

export { DeprecatedButton };
