const VALID_PROD_DOMAINS = [
  "us.fullscript.com",
  "ca.fullscript.com",
  "healthwavehq.com",
  "www.healthwavehq.com",
  "healthwavehq.ca",
  "www.healthwavehq.ca",
  "emersonecologics.com",
  "www.emersonecologics.com",
  "login.emersonecologics.com",
];

const EE_STAGING_DOMAIN = "staging.emersonecologics.com";

const isNodeEnv = (env: string) => {
  if (isSSR() || !window.ENV) {
    return process.env.NODE_ENV === env;
  }

  return window.ENV.NODE_ENV === env;
};

/**
 * Used for checking in what mode the fs-node server is running
 * - development
 * - staging
 * - production
 */
const isTargetEnv = (env: string) => {
  if (isSSR() || !window.ENV) {
    return process.env.TARGET_ENV === env;
  }

  return window.ENV.TARGET_ENV === env;
};

const isSSR = () => typeof window === "undefined";

const isMasterBranch = () => {
  if (isSSR() || !window.ENV) {
    return process.env.CI_COMMIT_REF_NAME === "master";
  }

  return window.ENV.CI_COMMIT_REF_NAME === "master";
};

const isProdDomain = () => {
  return !isSSR() && VALID_PROD_DOMAINS.includes(window.location.hostname);
};

const isSandboxDomain = () => {
  return (
    isSSR() ||
    window.location.hostname === "us-snd.fullscript.io" ||
    window.location.hostname === "ca-snd.fullscript.io"
  );
};

const isEEStagingDomain = () => {
  return !isSSR() && window.location.hostname === EE_STAGING_DOMAIN;
};

/**
 * Verifies if the current environment is a "production" JS bundle.
 * This will evaluate to `true` for review envs, staging, sandbox and production.
 *
 * See {@link isProduction} if you want to specifically target `us.fullscript.com` or `ca.fullscript.com`
 */
const isProductionBundle = () => {
  return isNodeEnv("production");
};

/**
 * Verifies if the current environment is `us.fullscript.com` or `ca.fullscript.com`.
 * This will only evaluate to `true` for the main production websites.
 *
 * See {@link isProductionBundle} if you want to target review envs, staging, sandbox and production
 */
const isProduction = () => {
  return isTargetEnv("production") || (isProductionBundle() && isMasterBranch() && isProdDomain());
};

/**
 * Verifies if the current environment is `us-snd.fullscript.io` or `ca-snd.fullscript.io`.
 * This will only evaluate to `true` for the sandbox websites.
 */
const isSandbox = () => {
  return isProductionBundle() && isMasterBranch() && isSandboxDomain();
};

/**
 * Verifies if the current environment is a `review env`, or `staging.r.fullscript.io`.
 * This will only evaluate to `true` for staging itself and review envs.
 *
 * See {@link isProductionBundle} if you want to target review envs, staging, sandbox and production
 */
const isStaging = () => {
  return isTargetEnv("staging") || (isProductionBundle() && !isProduction() && !isSandbox());
};

/**
 * Verifies if the current environment is `staging.emersonecologics.com`.
 * This will only evaluate to `true` for that environment.
 */
const isEEStaging = () => {
  return isStaging() && isEEStagingDomain();
};

/**
 * Verifies if the current env is a dev environment.
 */
const isDevelopment = () => {
  return isNodeEnv("development") || isTargetEnv("development");
};

/**
 * Verifies if the current env is a test environment.
 * I.E. when running jest.
 */
const isTest = () => {
  return isNodeEnv("test");
};

type PossibleEnvs = "production" | "sandbox" | "staging" | "development" | "test";

/**
 * Returns the current environment as a string, e.g. 'production', 'sandbox', 'staging', 'development', 'test'
 *
 * @returns {PossibleEnvs} the current running environment
 */
const currentEnv = (): PossibleEnvs => {
  if (isSSR() && process.env.TARGET_ENV) {
    return process.env.TARGET_ENV;
  } else if (isProduction()) {
    return "production";
  } else if (isSandbox()) {
    return "sandbox";
  } else if (isStaging()) {
    return "staging";
  } else if (isDevelopment()) {
    return "development";
  }

  return "test";
};

export {
  isDevelopment,
  isProductionBundle,
  isStaging,
  isSandbox,
  isProduction,
  isEEStaging,
  currentEnv,
  isTest,
  isSSR,
};
