import { useState, useEffect } from "react";

import { useLatestCallback } from "../useLatestCallback/useLatestCallback";

interface KeyComboParams {
  targetKeys: string[];
  withShift?: boolean;
  onPressed: () => void;
  disabled?: boolean;
}

/**
 * A hook to see if a combo of keys are pressed including the meta key.
 * Fo example: ExperimentTester drawer is opened when cmd+e is pressed.
 * @param targetKeys The keys we expect a combo for excluding the meta and shift keys. ex: ["e"]
 * @param withShift Whether or not the shift key is required to be pressed.
 * @param onPressed The function that gets called when the combo is pressed.
 */
const useKeyCombo = ({ targetKeys, withShift, onPressed, disabled }: KeyComboParams) => {
  const [metaKeyActive, setMetaKeyActive] = useState(false);
  const [shiftKeyActive, setShiftKeyActive] = useState(false);
  const [activeKeys, setActiveKeys] = useState(new Set());
  const onPressedLatest = useLatestCallback(onPressed);

  const hasActiveKeys = () => {
    const allKeysActive = targetKeys.every(key => activeKeys.has(key));

    return (
      allKeysActive &&
      metaKeyActive &&
      ((shiftKeyActive && withShift) || (!shiftKeyActive && !withShift))
    );
  };

  useEffect(() => {
    if (hasActiveKeys()) {
      onPressedLatest();
    }
  }, [metaKeyActive, shiftKeyActive, activeKeys]);

  const onKeyDown = ({ key, metaKey, shiftKey }: KeyboardEvent) => {
    if (metaKey || shiftKey) {
      setActiveKeys(new Set());
      if (metaKey) {
        setMetaKeyActive(true);
      }
      if (shiftKey) {
        setShiftKeyActive(true);
      }
    }

    if (targetKeys.includes(key)) {
      setActiveKeys(oldActiveKeys => {
        const newActiveKeys = new Set(oldActiveKeys);
        newActiveKeys.add(key);

        return newActiveKeys;
      });
    }
  };

  const onKeyUp = () => {
    setMetaKeyActive(false);
    setShiftKeyActive(false);
    setActiveKeys(new Set());
  };

  useEffect(() => {
    if (disabled) return;

    window.addEventListener("keydown", onKeyDown);
    window.addEventListener("keyup", onKeyUp);

    return () => {
      window.removeEventListener("keydown", onKeyDown);
      window.removeEventListener("keyup", onKeyUp);
    };
  }, []);
};

export { useKeyCombo };
