import { useContext } from "react";

import { GlobalContextUserRole, GlobalContext } from "@shared/context/GlobalConfig";
import { CAGlobals } from "@shared/data/globals/CA";
import { USGlobals } from "@shared/data/globals/US";
import { StorePlatform } from "@shared/types/StorePlatform";

const useSharedGlobalConfig = () => {
  const {
    country,
    adminImpersonating,
    role,
    setRole,
    isEmerson,
    e2e,
    iterableApiKey,
    rudderstackJsApiWriteKey,
    rudderstackDataPlaneUrl,
    isFs2Release,
  } = useContext(GlobalContext);

  const isPractitioner = role?.toLowerCase() === GlobalContextUserRole.PRACTITIONER;
  const isClerk = role?.toLowerCase() === GlobalContextUserRole.CLERK;
  const isClinic = isPractitioner || isClerk;

  const isPatient = role?.toLowerCase() === GlobalContextUserRole.PATIENT;
  const isUnauthenticated = role?.toLowerCase() === GlobalContextUserRole.UNAUTHENTICATED;

  const isCanada = country === CAGlobals.country;
  const isUS = country === USGlobals.country;

  const storePlatform = isEmerson ? StorePlatform.EMERSON : StorePlatform.FULLSCRIPT;

  return {
    country,
    isAdminImpersonating: adminImpersonating,
    isCanada,
    isUS,
    isEmerson,
    isPractitioner,
    isClerk,
    isClinic,
    isPatient,
    isUnauthenticated,
    role,
    setRole,
    e2e,
    iterableApiKey,
    rudderstackJsApiWriteKey,
    rudderstackDataPlaneUrl,
    isFs2Release,
    storePlatform,
  };
};
export { useSharedGlobalConfig };
